'use strict';

// eslint-disable-next-line no-multi-assign
window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

jQuery(function () {
	processInclude(require('./product/base'));
	processInclude(require('./product/compare'));
	processInclude(require('client/components/menu'));
	processInclude(require('base/components/cookie'));
	processInclude(require('base/components/footer'));
	processInclude(require('base/components/collapsibleItem'));
	processInclude(require('base/components/search'));
	processInclude(require('base/components/clientSideValidation'));
	processInclude(require('base/components/countrySelector'));
	processInclude(require('base/components/toolTip'));
	processInclude(require('client/components/miniCart'));
	processInclude(require('client/carousel'));
	processInclude(require('client/components/cookie'));
	processInclude(require('client/utils'));
	processInclude(require('analytics/tracking/tracking'));
	processInclude(require('client/product/quickView'));
	processInclude(require('client/thirdParty/reCAPTCHA'));
});

require('base/thirdParty/bootstrap');
require('base/components/spinner');

require('client/thirdParty/popper.min');
require('client/thirdParty/dropdown');
require('client/thirdParty/bootstrap-select');

'use strict';

const cart = require('../cart/cart');
const moveToWishlist = require('wishlists/components/miniCart').moveToWishlist;
const REMOVE_FROM_CART_ACTION = 'Cart-RemoveProductLineItem';

let updateMiniCart = true;

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
	var $html = $('<div>')
		.append($.parseHTML(html));

	var body = $html.find('.choice-of-bonus-product');
	var footer = $html.find('.modal-footer')
		.children();

	return {
		body: body,
		footer: footer
	};
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
	$('.modal-body')
		.spinner()
		.start();

	if ($('#chooseBonusProductModal').length !== 0) {
		$('#chooseBonusProductModal')
			.remove();
	}
	var bonusUrl;
	if (data.bonusChoiceRuleBased) {
		bonusUrl = data.showProductsUrlRuleBased;
	} else {
		bonusUrl = data.showProductsUrlListBased;
	}

	var htmlString = '<!-- Modal -->'
		+ '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
		+ '<span class="enter-message sr-only" ></span>'
		+ '<div class="modal-dialog choose-bonus-product-dialog" '
		+ 'data-total-qty="' + data.maxBonusItems + '"'
		+ 'data-UUID="' + data.uuid + '"'
		+ 'data-pliUUID="' + data.pliUUID + '"'
		+ 'data-addToCartUrl="' + data.addToCartUrl + '"'
		+ 'data-pageStart="0"'
		+ 'data-pageSize="' + data.pageSize + '"'
		+ 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
		+ 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
		+ '<!-- Modal content-->'
		+ '<div class="modal-content">'
		+ '<div class="modal-header">'
		+ '    <span class="">' + data.labels.selectprods + '</span>'
		+ '    <button type="button" class="close pull-right" data-dismiss="modal">'
		+ '        <span aria-hidden="true">&times;</span>'
		+ '        <span class="sr-only"> </span>'
		+ '    </button>'
		+ '</div>'
		+ '<div class="modal-body"></div>'
		+ '<div class="modal-footer"></div>'
		+ '</div>'
		+ '</div>'
		+ '</div>';
	$('body')
		.append(htmlString);
	$('.modal-body')
		.spinner()
		.start();

	$.ajax({
		url: bonusUrl,
		method: 'GET',
		dataType: 'json',
		success: function (response) {
			var parsedHtml = parseHtml(response.renderedTemplate);
			$('#chooseBonusProductModal .modal-body')
				.empty();
			$('#chooseBonusProductModal .enter-message')
				.text(response.enterDialogMessage);
			$('#chooseBonusProductModal .modal-header .close .sr-only')
				.text(response.closeButtonText);
			$('#chooseBonusProductModal .modal-body')
				.html(parsedHtml.body);
			$('#chooseBonusProductModal .modal-footer')
				.html(parsedHtml.footer);
			$('#chooseBonusProductModal')
				.modal('show');
			$.spinner()
				.stop();
		},
		error: function () {
			$.spinner()
				.stop();
		}
	});
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
	$('.minicart')
		.trigger('count:update', response);
	var messageType = response.error ? 'alert-danger' : 'alert-success';
	// show add to cart toast
	if (response.newBonusDiscountLineItem
		&& Object.keys(response.newBonusDiscountLineItem).length !== 0) {
		chooseBonusProducts(response.newBonusDiscountLineItem);
	} else {
		if ($('.add-to-cart-messages').length === 0) {
			$('body')
				.append(
					'<div class="add-to-cart-messages"></div>'
				);
		}

		$('.add-to-cart-messages')
			.append(
				'<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
				+ response.message
				+ '</div>'
			);

		setTimeout(function () {
			$('.add-to-basket-alert')
				.remove();
		}, 5000);
	}
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
	if (url) {
		$.ajax({
			url: url,
			method: 'GET',
			success: function () {
				// reporting urls hit on the server
			},
			error: function () {
				// no reporting urls hit on the server
			}
		});
	}
}

/**
 * init mini cart events
 */
function initMinicart() {
	cart();

	// selectors
	const $body = $('body');
	const $minicart = $('.minicart');
	const $popover = $('.minicart .popover');
	const $minicafrtQty = $('.minicart .minicart-quantity');
	const $minicartSubtotal = $('.minicart .sub-total');

	$minicart.on('count:update', function (event, count) {
		if (count && $.isNumeric(count.quantityTotal)) {
			$minicafrtQty.text(count.quantityTotal);
			$('.minicart .minicart-link').attr({
				'aria-label': count.minicartCountOfItems,
				title: count.minicartCountOfItems
			});
		}
	});

	$minicart.on('mouseenter focusin touchstart', function () {
		if ($('.search:visible').length === 0) {
			return;
		}
		var url = $minicart.data('action-url');
		var count = parseInt($minicafrtQty.text(), 10);

		if (count !== 0 && $('.minicart .popover.show').length === 0) {
			if (!updateMiniCart) {
				$popover.addClass('show');
				return;
			}

			$popover.addClass('show');
			$popover.spinner().start();
			$.get(url, function (data) {
				$popover.empty();
				$popover.append(data);
				updateMiniCart = false;
				$.spinner().stop();
			});
		}
	});
	$body.on('touchstart click', function (e) {
		if ($minicart.has(e.target).length <= 0) {
			$popover.removeClass('show');
		}
	});
	$minicart.on('mouseleave focusout', function (event) {
		if ((event.type === 'focusout' && $minicart.has(event.target).length > 0)
			|| (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
			|| $body.hasClass('modal-open')) {
			event.stopPropagation();
			return;
		}
		$popover.removeClass('show');
		$('html').removeClass('veiled');
	});
	$body.on('change', '.minicart .quantity', function () {
		if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
			// eslint-disable-next-line no-restricted-globals
			location.reload();
		}
	});
	$body.on('product:afterAddToCart', function (event, data, isCartPage) {
		updateMiniCart = true;

		// change modal content if data is a rendered template string
		if (typeof data === 'string') {
			if ($('#miniCartModalBody')) {
				$('#miniCartModalBody').remove();
			}

			$('#miniCartModal .modal-dialog .modal-content').append(data);
		} else if (data) {
			if (data.error) {
				$('#miniCartModal').on('shown.bs.modal', function () {
					$(this).modal('hide');
				}).on('.hidden.bs.modal', function () {
					$(this).off('shown.bs.modal');
				}).modal('hide');
			}
			if (data.renderedTemplate) {
				if ($('#miniCartModalBody')) {
					$('#miniCartModalBody').remove();
				}
				$('#miniCartModal .modal-dialog .modal-content').append(data.renderedTemplate);
			}
			if (data.cart && data.cart.totals && data.cart.totals.subTotal && $minicartSubtotal.length > 0) {
				$minicartSubtotal.text(data.cart.totals.subTotal);
			}
		}
	});
	$body.on('cart:update', function (event, data) {
		updateMiniCart = true;
		if (data.action === REMOVE_FROM_CART_ACTION && data && data.basket && data.basket.totals && data.basket.totals.subTotal && $minicartSubtotal.length > 0) {
			$minicartSubtotal.text(data.basket.totals.subTotal);
		}
	});

	$body.on('click', '.add-to-cart-reco', function () {
		let pid = $(this).data('pid');
		let addToCartUrl = $(this).data('url');
		let reqData = {
			pid: pid,
			quantity: 1
		};

		$('#miniCartModalBody').spinner().start();
		$(this)
			.trigger('updateAddToCartFormData', reqData);
		if (addToCartUrl) {
			$.ajax({
				url: addToCartUrl,
				method: 'POST',
				data: reqData,
				success: function (resData) {
					handlePostCartAdd(resData);
					$('body')
						.trigger('product:afterAddToCart', resData, true);
					$('#miniCartModalBody').spinner().stop();
					miniCartReportingUrl(resData.reportingURL);

					if ($('.cart.cart-page').length) {
						// eslint-disable-next-line no-restricted-globals
						location.reload();
					}
				},
				error: function () {
					$('#miniCartModalBody').spinner().stop();
				}
			});
		}
	});
}

module.exports = {
	initMinicart: initMinicart,
	moveToWishlist: moveToWishlist
};

'use strict';

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
	var pid;

	if ($el) {
		if ($el.hasClass('add-to-cart-compare')) {
			pid = $($el).data('pid');
		}
	}

	return pid;
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
	let qty = 0;

	if ($el) {
		if ($el.hasClass('add-to-cart-compare')) {
			qty = 1;
		}
	}

	return qty;
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
	return $('.add-to-cart-url')
		.val();
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
	$('.minicart')
		.trigger('count:update', response);
	var messageType = response.error ? 'alert-danger' : 'alert-success';
	// show add to cart toast
	if ($('.add-to-cart-messages').length === 0) {
		$('body')
			.append(
				'<div class="add-to-cart-messages"></div>'
			);
	}

	$('.add-to-cart-messages')
		.append(
			'<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
			+ response.message
			+ '</div>'
		);

	setTimeout(function () {
		$('.add-to-basket-alert')
			.remove();
	}, 5000);
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
	if (url) {
		$.ajax({
			url: url,
			method: 'GET',
			success: function () {
				// reporting urls hit on the server
			},
			error: function () {
				// no reporting urls hit on the server
			}
		});
	}
}

module.exports = {
	addToCart: function () {
		$(document)
			.on('click', 'button.add-to-cart-compare', function () {
				var addToCartUrl;
				var pid;

				$('body')
					.trigger('product:beforeAddToCart', this);

				pid = getPidValue($(this));

				addToCartUrl = getAddToCartUrl();

				var form = {
					pid: pid,
					quantity: getQuantitySelected($(this))
				};

				$(this)
					.trigger('updateAddToCartFormData', form);
				if (addToCartUrl) {
					$.ajax({
						url: addToCartUrl,
						method: 'POST',
						data: form,
						success: function (data) {
							handlePostCartAdd(data);
							$('body')
								.trigger('product:afterAddToCart', data);
							$.spinner()
								.stop();
							miniCartReportingUrl(data.reportingURL);
						},
						error: function () {
							$.spinner()
								.stop();
						}
					});
				}
			});
	},

	getPidValue: getPidValue,
	getQuantitySelected: getQuantitySelected,
	miniCartReportingUrl: miniCartReportingUrl
};

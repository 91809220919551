'use strict';

/**
 * Display the returned message.
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for contact us sign-up
 */
function displayMessage(data) {
	let $button = $('.subscribe-contact-us');

	$.spinner().stop();
	var status;
	if (data.success) {
		status = 'alert-success';
	} else {
		status = 'alert-danger';
	}

	if ($('.contact-us-signup-message').length === 0) {
		$('body').append(
			'<div class="contact-us-signup-message"></div>'
		);
	}
	$('.contact-us-signup-message')
		.append('<div class="contact-us-signup-alert text-center ' + status + '" role="alert">' + data.msg + '</div>');

	setTimeout(function () {
		$('.contact-us-signup-message').remove();
		$button.removeAttr('disabled');
	}, 3000);
}

function sendToBack($form, action, data) {
	$.ajax({
		type: 'POST',
		url: action,
		data: data,
		success: function (data) {
			$form[0].reset();
			displayMessage(data);
		},
		error: function (err) {
		}
	});
}

function onSubmitCaptcha() {
	var form = $('.captchaForm');
	var siteKey = $('input[name="cptsite"]').val();

	form.on('submit', function (e) {
		e.stopImmediatePropagation();
		e.preventDefault();
		let $form = $('.contact-us');
		let $formData = $form.serialize();
		let $action = $form.attr('action');

		grecaptcha.ready(function () {
			grecaptcha.execute(
				siteKey,
				{ action: 'submit' }
			).then(function (token) {
				$formData = $formData + '&token=' + token;
				sendToBack($form, $action, $formData);
			});
		});
	});
}
$(document).ready(function () {
	window.onSubmitCaptcha = onSubmitCaptcha();
});

module.exports = {
	onSubmitCaptcha: onSubmitCaptcha
}
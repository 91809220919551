'use strict';

/**
 * Checks the value of the dropdown.
 * @param {Object}  modifiedDropdown Object of the dropdown that was modified
 * @param {boolean} isFirstLoad Check first load to enable disable dropdown
 */
function checkDropdown(modifiedDropdown, isFirstLoad) {
	let dropdown = $('select');
	let wasClicked = Object.keys(modifiedDropdown).length !== 0;
	let multipleDropdown = $('.attribute .custom-select').length > 1;
	let hasMachineCompatibility = false;
	let hasSizeDropdown = false;

	for (let i = 0; i < dropdown.length; i += 1) {
		let arrowURLDarkGray = "data:image/svg+xml;utf8,<svg fill='%23222' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>";
		let arrowURLLightGray = "data:image/svg+xml;utf8,<svg fill='%23999' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>";
		let isDefaultValue = false;
		let selectedIsDefault = false;
		let dropdownID = dropdown[i].id;
		let dropdownVal = dropdown[i].value;
		let isAnySelected = dropdownVal !== '';
		let $machineCompatibility = $('.compatibilityDropdown');
		let $sizeDropdown = $('.select-productSize');
		let $currentDropdown = $('#' + dropdownID);
		let $modifiedDropdown = $('#' + modifiedDropdown.id);
		hasMachineCompatibility = $machineCompatibility.length > 0;
		hasSizeDropdown = $sizeDropdown.length > 0;

		if (wasClicked) {
			selectedIsDefault = modifiedDropdown.selectedOptions[0].id === 'defaultValue';
		}

		for (let j = 0; j < dropdown[i].length; j += 1) {
			let currentOptionVal = dropdown[i][j].value;
			if (dropdownVal === currentOptionVal) {
				isDefaultValue = dropdown[i][j].id === 'defaultValue';
			}
		}

		if (multipleDropdown && hasMachineCompatibility) {
			if (dropdownID === 'machineCompatibility-selection' && isFirstLoad && hasSizeDropdown) {
				$currentDropdown.attr('disabled', true);
			} else if (i === 0) {
				$machineCompatibility.attr('disabled', selectedIsDefault);
			}
		}

		if (dropdown[i].className.indexOf('compatibilityDropdown') !== -1
			|| dropdown[i].className.indexOf('attrDropdown') !== -1) {
			if (wasClicked) {
				$modifiedDropdown.css({
					color: '#222',
					'background-image': 'url("' + arrowURLDarkGray + '")'
				});
			} else {
				let color = (!isAnySelected || isDefaultValue) ? '#999' : '#222';
				let image = (!isAnySelected || isDefaultValue) ? arrowURLLightGray : arrowURLDarkGray;

				$currentDropdown.css({
					color: color,
					'background-image': 'url("' + image + '")'
				});
			}
		}
	}
}

$(document)
	.ready(function () {
		checkDropdown({}, true);
	});

$(document)
	.on('change', 'select[class*="select-"], .options-select', function (e) {
		checkDropdown(e.currentTarget, false);
	});

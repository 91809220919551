'use strict';

/**
 * Pre-select user's preferred machine.
 */
function selectPreferredMachine() {
	let selectEl = $('#machineCompatibility-selection');
	let currentPid = selectEl.attr('data-current-pid');
	let masterPid = selectEl.attr('data-master-pid');
	let url = selectEl.attr('data-preferredmachine-url');

	if (masterPid) {
		url += `?masterPid=${masterPid}&currentPid=${currentPid}`;
	}

	if (url) {
		$.ajax({
			url: url,
			success: function (data) {
				if (data.preferredMachineID && data.preferredMachineMasterID === masterPid) {
					let option = $(`#machineCompatibility-selection option[data-pid=${data.preferredMachineID}]`);
					if (option.length) {
						option.prop('selected', true);
						selectEl.selectpicker('refresh');
					}
				}

				$.spinner().stop();
			},
			error: function () {
				$.spinner().stop();
			}
		});
	}
}

/**
 * Save selected machine as user's preferred machine in the server session.
 * @param {Object} element DOM Element.
 */
function savePreferredMachine(element) {
	let url = element.getAttribute('data-setpreferredmachine-url');
	let masterPid = element.getAttribute('data-master-pid');
	let pid = element[element.selectedIndex].attributes['data-pid'];
	let form = {
		pmid: pid ? pid.value : null,
		masterPid: masterPid
	};

	$.ajax({
		url: url,
		method: 'POST',
		data: form,
		success: function (data) {
			$.spinner().stop();
		},
		error: function () {
			$.spinner().stop();
		}
	});
}

/**
 * Inititalize custom select and its event handlers
 */
function initialize() {
	if ($('#machineCompatibility-selection').length === 1) {
		$('#machineCompatibility-selection').on('change', function (e) {
			savePreferredMachine(e.currentTarget);
		});

		$('body').on('product:afterAttributeSelect', function (e, response) {
			$('#machineCompatibility-selection').selectpicker('refresh');
		});

		$('#machineCompatibility-selection').selectpicker();
	}
}

module.exports = {
	initialize: initialize,
	preselect: function () {
		if ($('.page').attr('data-action') === 'Product-Show' && $('#machineCompatibility-selection').length === 1) {
			$('#machineCompatibility-selection').on('loaded.bs.select', function (e) {
				selectPreferredMachine();
			});
		}
	},
	quickviewModal: function () {
		$('body').on('shown.bs.modal', '#quickViewModal', function () {
			initialize();
		});
	},
	cartModal: function () {
		$('body').on('shown.bs.modal', '#editProductModal', function () {
			initialize();
		});
	},
	wishlistModal: function () {
		$('body').on('shown.bs.modal', '#editWishlistProductModal', function () {
			initialize();
		});
	}
};

'use strict';

/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
	var $html = $('<div>').append($.parseHTML(html));

	var body = $html.find('.product-quickview');
	var footer = $html.find('.modal-footer').children();

	return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
function fillModalElement(selectedValueUrl) {
	$('.modal-body').spinner().start();
	$.ajax({
		url: selectedValueUrl,
		method: 'GET',
		dataType: 'json',
		success: function (data) {
			var parsedHtml = parseHtml(data.renderedTemplate);

			$('.modal-body').empty();
			$('.modal-body').html(parsedHtml.body);
			$('.modal-footer').html(parsedHtml.footer);
			$('.full-pdp-link').text(data.quickViewFullDetailMsg);
			$('#quickViewModal .full-pdp-link').attr('href', data.productUrl);
			$('#quickViewModal .size-chart').attr('href', data.productUrl);
			$('#quickViewModal .modal-header .close .sr-only').text(data.closeButtonText);
			$('#quickViewModal .enter-message').text(data.enterDialogMessage);
			$('#quickViewModal').modal('show');
			$('body').trigger('quickview:ready');

			$.spinner().stop();
		},
		error: function () {
			$.spinner().stop();
		}
	});
}

module.exports = {
	swatchClick: function () {
		$('body').on('click', '.color-attribute', function (e) {
			let $color = e.currentTarget;
			let url = $color.dataset.quickviewurl;
			fillModalElement(url);
		});
	}
};
